import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {useEffect} from 'react';
import { Provider } from 'react-redux';
import './design/App.css'
import Navigation from './Navigation.js';
import Home from './Home.js';
import Artists from './Components/Artists.js';
import Artworks from './Components/Artworks.js';
import Artwork from './Components/Artwork.js';
import Container from 'react-bootstrap/Container';
//import Footer from './Components/Footer.js';
import About from './Components/About.js';
import "bootstrap/dist/css/bootstrap.min.css";
import store from './redux/store';

function App() {
    useEffect(()=>{
console.log('onMount')
    },[])


  return (
      <Provider store={store}>
      <Router>
          <div className="App">
              <header>
                  <Navigation />
              </header>
              <main>
                  <Container>
                  <div className="content">
                      <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/artists" element={<Artists />} />
                          <Route path="/about" element={<About />} />
                          <Route path="/artworks" element={<Artworks />} />
                          <Route path="/:test/:artworktitleslug" element={<Artwork />} />
                      </Routes>
                  </div>
                  </Container>
              </main>
          </div>
      </Router>
      </Provider>
  );
}

export default App;
