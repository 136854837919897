import {useEffect, useState} from 'react';
/* This is a custom hook - they need to start with use*/

const useFetch = (url) => {
    const [data,setData]=useState(null);
    const [isPending,setIsPending]=useState(true);
    const [error,setError]=useState(null);

    useEffect(()=>{
            fetch(url)
                .then(res=>{
                    if(!res.ok){
                        throw Error('could not fetch the data for that resource');
                    }
                    return res.json();
                })
                .then(data=>{
                    setData(data);
                    setIsPending(false);
                    setError(null);
                })
                .catch(err=>{
                    setIsPending(false);
                    setError(err.message);
                })
    },[url]);
    
    return{data,isPending,error}
}

export default useFetch
