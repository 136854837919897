import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
//Taken from https://react-bootstrap.netlify.app/docs/components/list-group
//import {Link} from 'react-router-dom';

const ArtworksList=(props)=>{
    const artworks=props.artworks;
    console.log(props)
    return(
        <ListGroup as="ol">
            {artworks.map((obj)=>{
               if(obj.active){
                   return(
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                    key={obj.id}
                >
                    <div className="ms-2 me-auto">
                        <Alert variant="light">
                            <Alert.Link href={"/artwork/"+ obj.artworkslug}>{obj.artworktitleen}</Alert.Link> 
                        </Alert>
                    </div>
                    {obj.artworkreservationstate===1 
                        ?<Badge bg="success" pill>available</Badge>
                        :<Badge bg="warning" pill>reserved or sold</Badge>
                    }
                </ListGroup.Item>
                   )}
            })
            }
        </ListGroup>
    )
}

export default ArtworksList
