import axios from 'axios';
import {GET_ARTISTS} from './types';
import {domain} from './sharedvariables';

export function getArtists(){
    return function(dispatch){
        console.log("Action call");
        axios({
            method:'get',
            url:`${domain}/api/artists`,
            headers:{}
        }).then(res=>{
            dispatch({
                type:GET_ARTISTS,
                // The payload is going to be reduced inside the getReducer
                payload:res.data
            });
        }).catch(err=>console.log(err));
    }
}
