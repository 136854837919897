import {MapContainer, TileLayer,Marker, Popup} from "react-leaflet";
import Image from 'react-bootstrap/Image';
import osm from "./Leavelet/osm-providers";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import coincoinlogo from '../design/coincoin_logo_map.png';
/*
Problem with the tiles - only half of the map is visible
https://stackoverflow.com/questions/51412179/leaflet-map-partially-loading-within-react-project
conclusion: Parent object needs to have a width:100%
*/
const LessingMap=()=>{
    const position=[47.358210, 8.524455];
    const ZOOM_LEVEL=13;
    const markerIcon=new L.Icon({
        iconUrl:require("../design/marker.png"),
        iconSize:[30,40],
    });

    return(
        <div>
            <br />
            <MapContainer center={position} zoom={ZOOM_LEVEL} >
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
                <Marker position={position} icon={markerIcon}>
                    <Popup>
                        <p class="center">
                        JOINTARTSPACE   
                        </p>
                        <p class="center">
                        catering by   
                        </p>
                        <p class="center">
                        <Image src={coincoinlogo} thumbnail />
                        </p>
                        <p class="center">Location: Lessingstrasse 15, 8002-Zurich
                            <br />
                            Friday-Sunday from 11am-8pm
                        </p>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
        )
}

export default LessingMap
