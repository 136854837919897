import { GET_ARTISTS } from '../actions/types.js';

const initialState={
    items:[],
    item:{}
};

const getArtists=(state=initialState, action)=>{
    switch(action.type){
        case GET_ARTISTS:
            console.log("get_Artists");
            return {
                ...state,
                items:action.payload
            };
        default:
            return state;
    }
}
export default getArtists
