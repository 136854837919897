import axios from 'axios';
import {domain} from './sharedvariables';
import {GET_ARTWORKS} from './types';

export function getArtworks(){
    return function(dispatch){
        console.log("Action call");
        axios({
            method:'get',
            url:`${domain}/api/artistartwork/`,
            headers:{}
        }).then(res=>{
            dispatch({
                type:GET_ARTWORKS,
                // The payload is going to be reduced inside the getReducer
                payload:res.data
            });
        }).catch(err=>console.log(err));
    }
}
