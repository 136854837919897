import { GET_ARTWORKS } from '../actions/types.js';

const initialState={
    items:[],
    item:{}
};

const reduceArtworks=(state=initialState, action)=>{
    switch(action.type){
        case GET_ARTWORKS:
            console.log("get_Artworks");
            return {
                ...state,
                items:action.payload
            };
        default:
            return state;
    }
}
export default reduceArtworks
