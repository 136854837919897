import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getArtists } from '../redux/actions/getArtists';

class Artists extends Component{
    componentWillMount(){
        this.props.getArtists();
    }
render(){
    const Artistsitems=this.props.artists.map(artist=>(
        <div key={artist.id}>
            <h2>{artist.id} {artist.artistgivenname} {artist.artistname}</h2>
        </div>

    ));
    return(
        <div>
            {Artistsitems}
        </div>
        )
}
}
Artists.propTypes={
    getArtists:PropTypes.func.isRequired,
    artists:PropTypes.array.isRequired
};

const mapStateToProps=state=>(
    {
        artists:state.artists.items
    });
export default connect(mapStateToProps,{getArtists})(Artists)
