import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getArtworks } from '../redux/actions/callArtworks';
import ArtworksList from './ArtworksList';
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"

class Artworks extends Component{
    componentWillMount(){
        this.props.getArtworks();
    }
render(){
    const Artworkitems=this.props.artistsartworks.map(obj=>(
        <Card key={obj.id}>
            <Card.Header>
                <Row md={2}>{obj.artistgivenname} {obj.artistname} - List of works</Row>
            </Card.Header>
            <Card.Body>
                <ArtworksList artworks={obj.artworks} />
            </Card.Body>
        </Card>
    ));
    return(
        <div>
            {Artworkitems}
        </div>
        )
}
}
Artworks.propTypes={
    getArtworks:PropTypes.func.isRequired,
    artistsartworks:PropTypes.array.isRequired
};

const mapStateToProps=state=>(
    {
        artistsartworks:state.artistsartworks.items
    });
export default connect(mapStateToProps,{getArtworks})(Artworks)
