import { useParams } from "react-router-dom";
import useFetch from "./useFetch";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



const Artwork=()=>{
    const {artworktitleslug}=useParams();
    const {data, error, isPending}=useFetch('https://www.jointart.space/backend/api/artwork/'+artworktitleslug);
//    const obj=data
    console.log(data)
    return(
        <div>
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && 
                <Container>
                    <Row>
                    <Col>
                        <Card style={{ width: '16rem' }}>
                            <Card.Img variant="top" src="" />
                            <Card.Body>
                                <Card.Title>{data.artistgn} {data.artistname} - {data.artworktitleen}</Card.Title>
                                <Card.Text>
                                    Description: {data.artworkdescriptioneng}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '16rem' }}>
                            <Card.Img variant="top" src="" />
                            <Card.Body>
                                <Card.Title>{data.artistgn} {data.artistname} - {data.artworktitleger}</Card.Title>
                                <Card.Text>
                                    Beschreibung: {data.artworkdescriptionger}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            }
        </div>
            )
}

export default Artwork
