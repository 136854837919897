import poster from '../design/callme_final.png';
import Card from 'react-bootstrap/Card';

const Event2=()=>{
    return(
        <>
            <Card style={{border:0}}>
                <Card.Body>
                    <Card.Title>6.-8. December 2024 - VN Residency, Lessingstrasse 15, 8002-Zürich</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card.Body>
                <Card.Img variant="bottom" src={poster} />
            </Card>
        </>
        )
}

export default Event2
