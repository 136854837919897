import React from "react"

function About(){
    return(
        <div>
                    <div className="container text-center text-md-start bg-light mt-5">
                        <div className="row mt-3">
                            <div className="col-md-5 col-lg-6 col-xl-5 bg-light mx-auto mb-8">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <i className=""></i>JointArtSpace
                                </h6>
                                <p>As founding members of Joint Art Space, our mission is to promote art not only as a vehicle for creative expression but also as a means to advance values like inclusion, diversity, and equality. We strive to foster dialogue and provoke discussion on sensitive and challenging topics, encouraging greater tolerance for differences, empathy with others, and a broader understanding of ourselves. Through this, we aim to demonstrate that individual engagement with the world can lead to meaningful, tangible change.</p>
                                <p>&#169;2023-2024</p>
                            </div>


                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6>Contact</h6>
                                <p>Zurich, CH</p>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    office@jointart.space
                                </p>
                                <p><i className="fab fa-instagram me-3"></i><a href="https://instagram.com/jointartspace.zurich" className="text-reset">jointartspace.zurich</a></p>
                            </div>
                        </div>
                    </div>
        </div>
        )
}

export default About
