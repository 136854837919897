import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Navigation2() {
  return (
      <>
          {
              <Navbar key={"lg"} expand={"lg"} className="bg-body-tertiary mb-3">
                  <Container fluid>
                      <Navbar.Brand as={Link} to={"/"}></Navbar.Brand>JOINTARTSPACE<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
                      <Navbar.Offcanvas
                          id={`offcanvasNavbar-expand-${"lg"}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
                          placement="end"
                      >
                          <Offcanvas.Header closeButton>
                              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
                                  JOINTARTSPACE
                              </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                              <Nav className="justify-content-end flex-grow-1 pe-3">
                                  <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                                  <Nav.Link as={Link} to={"/about"}>About</Nav.Link>
                                  <Nav.Link as={Link} to={"/artwork/"}>Artists</Nav.Link>
                                  <Nav.Link as={Link} to={"/artwork/"}>Previous Events</Nav.Link>
                              </Nav>
                          </Offcanvas.Body>
                      </Navbar.Offcanvas>
                  </Container>
              </Navbar>
          }
      </>
  );
}

export default Navigation2;
