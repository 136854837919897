//import BasicMap from './Components/BasicMap.js';
import LessingMap from './Components/LessingMap.js';
import Event2 from './Components/Event2';

const Home=()=>{
    return(
        <div className="home">
            <Event2 />
            <LessingMap />
        </div>
        )
}

export default Home
