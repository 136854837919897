import {createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

//To enabel visibility within the dev tools:
//https://github.com/zalmoxisus/redux-devtools-extension#usage
import { composeWithDevTools } from 'redux-devtools-extension';


const initialState={};
const middleware=[thunk];

const store=createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
    applyMiddleware(...middleware),
));

export default store;
